import Page from 'components/Page'
import Section from 'components/Section'
import { MetaData } from 'components/Seo'
import { PageProps, graphql } from 'gatsby'
import React, { createContext } from 'react'
import { completeUrlPrefix } from 'utils/dataHelpers'
import { Locale, MetaType } from 'utils/enums'
import { isRunningInBrowser } from 'utils/tuixLocalStorage'
import { File, LongText, NodeQuery, Raw } from 'utils/types/common.types'
import SuccessStoryBody from './components/SuccessStoryBody'
import SuccessStoryIntroduction from './components/SuccessStoryIntroduction'
import SuccessStoryMainImage from './components/SuccessStoryMainImage'
import { SuccessStoryResultModel } from './components/SuccessStoryMainImage/SuccessStoryMainImage'
import CardSlider from 'components/CardSlider'
import SummaryCard from 'components/SummaryCard'
import { MainVariant } from 'tuix-webcomponents'
import SuccessStoryCallToAction from './components/SuccessStoryCallToAction'

interface SuccessStoryModel {
    createdAt: string
    slug: string
    node_locale: Locale
    title: string
    mainMedia: File
    hideResults: boolean
    results: SuccessStoryResultModel[]
    introduction: Raw
    introductionImportant: Raw
    logo: File
    logoDark: File
    logoVertical: File
    logoVerticalDark: File
    content: Raw
    testimony: LongText
    testimonyAuthor: string
    testimonyAuthorRole: string
    images: File[]
    about: Raw
    industry: string
    country: string
    stats: string[]
    technologies: string[]
    miniatureImage: File
    companyName: string
    sliderImagesTitle: string
    description: string
    keywords: string[]
}

interface TitleModel {
    title: string
}

interface SuccessStoryDataQueries {
    contentSuccessStory: NodeQuery<SuccessStoryModel>
    quotationMarksIcon: NodeQuery<File>
    quotationMarksIconDark: NodeQuery<File>
    otherStories?: NodeQuery<SuccessStoryModel>
    contactUsText: NodeQuery<{ text: string }>
    readStoryText: NodeQuery<{ text: string }>
    countryTitle: NodeQuery<TitleModel>
    industryTitle: NodeQuery<TitleModel>
    technologiesUsedTitle: NodeQuery<TitleModel>
    shareStoryTitle: NodeQuery<TitleModel>
    moreStoriesTitle: NodeQuery<TitleModel>
    bookCallTitle: NodeQuery<TitleModel>
    similarChallengesTitle: NodeQuery<TitleModel>
    copiedToClipboardText: NodeQuery<{ text: string }>
}

interface Props extends PageProps {
    pageContext: {
        slug: string
        locale: Locale
        id: string
    }
    data: SuccessStoryDataQueries
}

export const slugContext = createContext()

export default ({ pageContext: { locale }, data }: Props): JSX.Element => {
    const {
        contentSuccessStory: { nodes: contentSuccessStory },
        quotationMarksIcon: { nodes: quotationMarksIcon },
        quotationMarksIconDark: { nodes: quotationMarksIconDark },
        otherStories: { nodes: otherStories },
        contactUsText: { nodes: contactUsText },
        countryTitle: { nodes: countryTitle },
        industryTitle: { nodes: industryTitle },
        technologiesUsedTitle: { nodes: technologiesUsedTitle },
        shareStoryTitle: { nodes: shareStoryTitle },
        moreStoriesTitle: { nodes: moreStoriesTitle },
        bookCallTitle: { nodes: bookCallTitle },
        similarChallengesTitle: { nodes: similarChallengesTitle },
        readStoryText: { nodes: readStoryText },
        copiedToClipboardText: { nodes: copiedToClipboardText },
    } = data

    const {
        title,
        mainMedia,
        results,
        introduction,
        introductionImportant,
        logo,
        logoDark,
        logoVertical,
        logoVerticalDark,
        content,
        testimony,
        testimonyAuthor,
        testimonyAuthorRole,
        images,
        about,
        industry,
        country,
        stats,
        technologies,
        companyName,
        sliderImagesTitle,
        miniatureImage,
        description,
        keywords,
        hideResults,
    } = contentSuccessStory[0]

    const metaData: MetaData = {
        title,
        url: '',
        description: { description },
        keywords,
        image: { file: { url: completeUrlPrefix(miniatureImage.file.url) } },
        type: MetaType.ARTICLE,
    }
    if (isRunningInBrowser) {
        metaData.url = window.location.toString()
    }

    return (
        <slugContext.Provider value={{ slug: 'oppositeSlug' }}>
            <Page metaData={metaData} locale={locale}>
                <Section fadein={false}>
                    <SuccessStoryMainImage
                        media={mainMedia}
                        title={title}
                        results={results}
                        hideResults={hideResults}
                    ></SuccessStoryMainImage>
                </Section>
                <Section fadein={false}>
                    <SuccessStoryIntroduction
                        content={introduction}
                        contentImportant={introductionImportant}
                        logoLight={logo}
                        logoDark={logoDark}
                        logoVertical={logoVertical}
                        logoVerticalDark={logoVerticalDark}
                    ></SuccessStoryIntroduction>
                </Section>
                <Section fadein={false}>
                    <SuccessStoryBody
                        content={content}
                        testimony={testimony?.internal?.content}
                        testimonyAuthor={testimonyAuthor}
                        testimonyAuthorRole={testimonyAuthorRole}
                        quotationMarksIcon={quotationMarksIcon[0]}
                        quotationMarksIconDark={quotationMarksIconDark[0]}
                        images={images}
                        aboutContent={about}
                        industry={industry}
                        country={country}
                        stats={stats}
                        technologies={technologies}
                        technologiesUsedTitle={technologiesUsedTitle[0].title}
                        shareStoryTitle={shareStoryTitle[0].title}
                        countryTitle={countryTitle[0].title}
                        industryTitle={industryTitle[0].title}
                        sliderImagesTitle={sliderImagesTitle}
                        copiedToClipboardText={copiedToClipboardText[0].text}
                        companyName={companyName}
                    ></SuccessStoryBody>
                </Section>
                {otherStories.length > 0 && (
                    <Section fadein={false} backgroundColor={MainVariant.SECONDARY}>
                        <CardSlider title={moreStoriesTitle[0].title}>
                            {otherStories.map((story, index) => (
                                <SummaryCard
                                    key={index}
                                    entry={{
                                        title: story.title,
                                        image: story.miniatureImage,
                                        content: story.introduction,
                                        path: `story/${story.slug}`,
                                    }}
                                    readMoreButtonText={readStoryText[0].text}
                                    locale={story.node_locale}
                                    className="embla__slide"
                                ></SummaryCard>
                            ))}
                        </CardSlider>
                    </Section>
                )}
                <Section
                    fadein={false}
                    backgroundColor={
                        otherStories.length > 0 ? MainVariant.PRIMARY : MainVariant.SECONDARY
                    }
                >
                    <SuccessStoryCallToAction
                        actionText={bookCallTitle[0].title}
                        locale={locale}
                        contactUsText={contactUsText[0].text}
                        questionText={`${similarChallengesTitle[0].title} ${companyName}?`}
                    ></SuccessStoryCallToAction>
                </Section>
            </Page>
        </slugContext.Provider>
    )
}

export const query = graphql`
    query QuerySuccessStory($slug: String!, $locale: String!) {
        contentSuccessStory: allContentfulSuccessStory(
            filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
        ) {
            nodes {
                contentful_id
                createdAt
                slug
                node_locale
                title
                mainMedia {
                    file {
                        url
                    }
                }
                hideResults
                results {
                    icon {
                        file {
                            url
                        }
                    }
                    measure
                    description
                }
                introduction {
                    raw
                }
                introductionImportant {
                    raw
                }
                logo {
                    file {
                        url
                    }
                }
                logoDark {
                    file {
                        url
                    }
                }
                logoVertical {
                    file {
                        url
                    }
                }
                logoVerticalDark {
                    file {
                        url
                    }
                }
                content {
                    raw
                }
                testimony {
                    internal {
                        content
                    }
                }
                testimonyAuthor
                testimonyAuthorRole
                images {
                    file {
                        url
                    }
                }
                about {
                    raw
                }
                industry
                country
                stats
                technologies
                companyName
                sliderImagesTitle
                miniatureImage {
                    file {
                        url
                    }
                }
                description
                keywords
            }
        }
        quotationMarksIcon: allContentfulAsset(
            filter: { contentful_id: { eq: "36vaQfsl6LoKxkWC1aQIrK" } }
        ) {
            nodes {
                file {
                    url
                }
            }
        }
        quotationMarksIconDark: allContentfulAsset(
            filter: { contentful_id: { eq: "6mjSdSSLMyx0CvQ5RJFS6m" } }
        ) {
            nodes {
                file {
                    url
                }
            }
        }
        otherStories: allContentfulSuccessStory(
            sort: { order: DESC, fields: createdAt }
            filter: { slug: { ne: $slug }, node_locale: { eq: $locale } }
        ) {
            nodes {
                slug
                node_locale
                title
                introduction {
                    raw
                }
                miniatureImage {
                    file {
                        url
                    }
                }
            }
        }

        contactUsText: allContentfulTextButton(
            filter: {
                contentful_id: { eq: "1bnuvPyb0a1DhPXThdGH7t" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                text
            }
        }

        readStoryText: allContentfulTextButton(
            filter: {
                contentful_id: { eq: "3eraTOizsr8rOVCiSlcOQI" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                text
            }
        }

        countryTitle: allContentfulSection(
            filter: {
                contentful_id: { eq: "3uwvvPVkgHANyqiCs5cFdv" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                contentful_id
                title
            }
        }

        industryTitle: allContentfulSection(
            filter: {
                contentful_id: { eq: "1TFBXW5sOSkrTEMLzlLtPI" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                contentful_id
                title
            }
        }

        technologiesUsedTitle: allContentfulSection(
            filter: {
                contentful_id: { eq: "51xX0ENKzRcg5l40WqJD0h" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                contentful_id
                title
            }
        }

        shareStoryTitle: allContentfulSection(
            filter: {
                contentful_id: { eq: "3zO5L0LmcAHexm9p4Yj81C" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                contentful_id
                title
            }
        }

        moreStoriesTitle: allContentfulSection(
            filter: { contentful_id: { eq: "jKoF6UrelUyJy9qiPM2PA" }, node_locale: { eq: $locale } }
        ) {
            nodes {
                node_locale
                contentful_id
                title
            }
        }

        bookCallTitle: allContentfulSection(
            filter: {
                contentful_id: { eq: "6jDuXpPjr9SuzNivGzc2pQ" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                contentful_id
                title
            }
        }

        similarChallengesTitle: allContentfulSection(
            filter: {
                contentful_id: { eq: "43sy9P1Ok1ArjFmwAHeZJ0" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                contentful_id
                title
            }
        }

        copiedToClipboardText: allContentfulTextButton(
            filter: {
                contentful_id: { eq: "2p1yuxDTuY1wQHp0NzK5EX" }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                node_locale
                text
            }
        }
    }
`
