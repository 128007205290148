import clsx from 'clsx'
import { TuixIconComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { TuixToastComponent } from 'components/TuixWebcomponents/TuixToast.wrapped'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { LinkedinShareButton } from 'react-share'
import { IconName, IconSizeVariant, TextSize, TextVariant, ToastType } from 'tuix-webcomponents'
import { isRunningInBrowser } from 'utils/tuixLocalStorage'
import './SuccessStoryShare.scss'

export interface Props {
    title: string
    shareStoryText: string
    copiedToClipboardText: string
}

export default ({ title, shareStoryText, copiedToClipboardText }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    const currentUrl = isRunningInBrowser ? window.location.toString() : ''
    const [showToast, setShowToast] = React.useState(false)

    const copyToClipboard = (e: React.MouseEvent, text: string) => {
        e.stopPropagation()
        navigator.clipboard.writeText(text)
        setShowToast(true)
    }

    return (
        <div className={clsx('success-story-share-container', isDarkMode && 'dark')}>
            <TuixTextComponent
                dark={isDarkMode}
                size={TextSize.Body1}
                className="success-story-share-text"
            >
                <p>{shareStoryText}</p>
            </TuixTextComponent>
            <div className="success-story-share-icons">
                <LinkedinShareButton url={currentUrl} title={title}>
                    <TuixIconComponent
                        name={IconName.LINKEDIN}
                        size={IconSizeVariant.MEDIUM}
                        fill={isDarkMode ? TextVariant.MAIN : TextVariant.DARK_MAIN}
                        dark={isDarkMode}
                    />
                </LinkedinShareButton>
                <TuixIconComponent
                    name={IconName.SHARE}
                    color={TextVariant.MAIN}
                    dark={isDarkMode}
                    size={IconSizeVariant.MEDIUM}
                    onClick={(e) => copyToClipboard(e, currentUrl)}
                    className="success-story-share-icon"
                ></TuixIconComponent>
            </div>
            <TuixToastComponent
                type={showToast ? ToastType.TEXT : ToastType.NONE}
                dark={isDarkMode}
                onClose={() => setShowToast(false)}
                className="success-story-toast"
                visibilityDuration={3000}
            >
                {copiedToClipboardText}
            </TuixToastComponent>
        </div>
    )
}
