import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { TUIX_RICH_RENDER_OPTIONS_POST } from 'components/RichContenfulTuix/RichContentfulTuixEntry.consts'
import RichTuixTextBlog from 'components/RichContenfulTuix/RichTuixTextBlog'
import { TAG } from 'components/RichContenfulTuix/RichTuixTextBlog/RichTuixTextBlog'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import React, { useEffect, useState } from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { File, Raw } from 'utils/types/common.types'
import './SuccessStoryIntroduction.scss'
import ImageSwitcher from 'components/ImageSwitcher'

export interface Props {
    content: Raw
    contentImportant?: Raw
    logoLight: File
    logoDark: File
    logoVertical: File
    logoVerticalDark: File
}

export default ({
    content,
    contentImportant,
    logoLight,
    logoDark,
    logoVertical,
    logoVerticalDark,
}: Props): JSX.Element => {
    const { isTablet } = useDeviceWidth()
    const [currentLogoLight, setCurrentLogoLight] = useState(logoLight)
    const [currentLogoDark, setCurrentLogoDark] = useState(logoDark)

    const richObject = JSON.parse(content.raw)
    const renderOptionsForContent = TUIX_RICH_RENDER_OPTIONS_POST([])
    renderOptionsForContent.renderNode[BLOCKS.PARAGRAPH] = (_, children: JSX.Element) => (
        <RichTuixTextBlog tag={TAG.H4} size={TextSize.XS}>
            {children}
        </RichTuixTextBlog>
    )
    const renderOptionsForImportantContent = TUIX_RICH_RENDER_OPTIONS_POST([])
    renderOptionsForImportantContent.renderNode[BLOCKS.PARAGRAPH] = (_, children: JSX.Element) => (
        <RichTuixTextBlog tag={TAG.H4} size={TextSize.XS} color={TextVariant.MAIN}>
            {children}
        </RichTuixTextBlog>
    )

    const contentReact = documentToReactComponents(richObject, renderOptionsForContent)
    const contentImportantReact = contentImportant
        ? documentToReactComponents(
              JSON.parse(contentImportant.raw),
              renderOptionsForImportantContent,
          )
        : null

    useEffect(() => {
        if (isTablet) {
            setCurrentLogoLight(logoVertical)
            setCurrentLogoDark(logoVerticalDark)
        } else {
            setCurrentLogoLight(logoLight)
            setCurrentLogoDark(logoDark)
        }
    }, [isTablet])

    return (
        <div className="success-story-introduction-container">
            <ImageSwitcher
                lightImageURL={currentLogoLight.file.url}
                darkImageURL={currentLogoDark.file.url}
                className="introduction-logo"
                alt="success story logo"
            ></ImageSwitcher>
            <div className="introduction-text-container">
                {contentReact}
                {contentImportantReact}
            </div>
        </div>
    )
}
