import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import './SuccessStoryTechnologies.scss'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'

export interface Props {
    technologies: string[]
    sectionTitle: string
}

export default ({ technologies, sectionTitle }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isMobile, isTablet } = useDeviceWidth()

    return (
        <div className="success-story-technologies-container">
            <TuixTextComponent
                dark={isDarkMode}
                size={TextSize.S}
                className="technologies-title"
                color={TextVariant.TITLE_BLUE}
            >
                <h4>{sectionTitle}</h4>
            </TuixTextComponent>
            <div className="technologies">
                {technologies.length > 0 &&
                    technologies.map((technology, index) => (
                        <div className="technology-group" key={index}>
                            <div className="circle"></div>
                            <TuixTextComponent
                                dark={isDarkMode}
                                size={isMobile || isTablet ? TextSize.Link : TextSize.XS}
                                className="technology"
                                key={index}
                                color={
                                    isMobile || isTablet
                                        ? TextVariant.PRIMARY
                                        : TextVariant.TITLE_BLUE
                                }
                            >
                                <p>{technology}</p>
                            </TuixTextComponent>
                            {technologies.length > 1 && index !== technologies.length - 1 && (
                                <svg width="8" height="98px" className="dotted-line">
                                    <path d="M 4 0 V 100" strokeWidth="4" strokeDasharray="4 6" />
                                </svg>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    )
}
