import clsx from 'clsx'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { File } from 'utils/types/common.types'
import './SuccessStoryMainImage.scss'

export interface SuccessStoryResultModel {
    icon: File
    measure: string
    description: string
}

export interface Props {
    media: File
    title: string
    results: SuccessStoryResultModel[]
    hideResults?: boolean
}

export default ({ media, title, results, hideResults }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const isVideo = media.file.url.includes('mp4')

    return (
        <div className={clsx('success-story-main-image-color-container', isDarkMode && 'dark')}>
            <div className="success-story-main-image-container">
                <TuixTextComponent
                    color={TextVariant.LIGHT}
                    dark={isDarkMode}
                    size={TextSize.L}
                    className="success-story-title"
                >
                    <h1>{title}</h1>
                </TuixTextComponent>
                <div className="success-story-media-cell">
                    {isVideo ? (
                        <video
                            controls
                            className="success-story-main-image"
                            preload="metadata"
                            autoPlay
                            loop
                            playsInline
                        >
                            <source src={`${media.file.url}#t=0.4`} type="video/mp4" />
                        </video>
                    ) : (
                        <img className="success-story-main-image" src={media.file.url} alt="" />
                    )}
                </div>
                {!hideResults && (
                    <div className="success-story-results">
                        {results.map((result, index) => (
                            <div key={index} className="success-story-result">
                                <img
                                    src={result.icon.file.url}
                                    alt=""
                                    className="success-story-result-icon"
                                    width="64px"
                                    height="64px"
                                />
                                <div className="result-description">
                                    <TuixTextComponent
                                        color={TextVariant.LIGHT}
                                        dark={isDarkMode}
                                        size={TextSize.M}
                                    >
                                        <p>{result.measure}</p>
                                    </TuixTextComponent>
                                    <TuixTextComponent
                                        color={TextVariant.LIGHT}
                                        dark={isDarkMode}
                                        size={TextSize.XS}
                                    >
                                        <p className="uppercase">{result.description}</p>
                                    </TuixTextComponent>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
