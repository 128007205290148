import * as React from 'react'

import { EventName, createComponent } from '@lit-labs/react'
import { TuixToast } from 'tuix-webcomponents'

export const TuixToastComponent = createComponent({
    tagName: 'tuix-toast',
    elementClass: TuixToast,
    react: React,
    events: {
        onClose: 'on-close' as EventName<CustomEvent>,
    },
})
