import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import CardSlider from 'components/CardSlider'
import { TUIX_RICH_RENDER_OPTIONS_POST } from 'components/RichContenfulTuix/RichContentfulTuixEntry.consts'
import RichTuixTextBlog from 'components/RichContenfulTuix/RichTuixTextBlog'
import { TAG } from 'components/RichContenfulTuix/RichTuixTextBlog/RichTuixTextBlog'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import { File, Raw } from 'utils/types/common.types'
import SuccessStoryShare from '../SuccessStoryShare'
import SuccessStoryTechnologies from '../SuccessStoryTechnologies'
import SuccessStoryTestimony from '../SuccessStoryTestimony'
import SucessStoryCompanyInfo from '../SucessStoryCompanyInfo'
import './SuccessStoryBody.scss'

export interface Props {
    content: Raw
    testimony: string
    testimonyAuthor: string
    testimonyAuthorRole: string
    quotationMarksIcon: File
    quotationMarksIconDark: File
    images: File[]
    aboutContent: Raw
    industry: string
    country: string
    stats?: string[]
    technologies: string[]
    technologiesUsedTitle: string
    shareStoryTitle: string
    countryTitle: string
    industryTitle: string
    sliderImagesTitle: string
    copiedToClipboardText: string
    companyName: string
}

export default ({
    content,
    testimony,
    testimonyAuthor,
    testimonyAuthorRole,
    quotationMarksIcon,
    quotationMarksIconDark,
    images,
    aboutContent,
    industry,
    country,
    stats,
    technologies,
    technologiesUsedTitle,
    shareStoryTitle,
    countryTitle,
    industryTitle,
    sliderImagesTitle,
    copiedToClipboardText,
    companyName,
}: Props): JSX.Element => {
    const { isDesktop } = useDeviceWidth()

    const richObject = JSON.parse(content.raw)
    const renderOptionsForContent = TUIX_RICH_RENDER_OPTIONS_POST([])
    renderOptionsForContent.renderNode[BLOCKS.HEADING_3] = (_, children: JSX.Element) => (
        <RichTuixTextBlog tag={TAG.H3} size={TextSize.M}>
            {children}
        </RichTuixTextBlog>
    )
    const contentReact = documentToReactComponents(richObject, renderOptionsForContent)

    const richObjectAbout = JSON.parse(aboutContent.raw)
    const aboutContentReact = documentToReactComponents(richObjectAbout, renderOptionsForContent)

    return (
        <div className="success-story-body-container">
            <div className="success-story-details">
                <SucessStoryCompanyInfo
                    country={country}
                    industry={industry}
                    stats={stats}
                    aboutElement={aboutContentReact}
                    countryTitle={countryTitle}
                    industryTitle={industryTitle}
                ></SucessStoryCompanyInfo>
                <SuccessStoryTechnologies
                    technologies={technologies}
                    sectionTitle={technologiesUsedTitle}
                ></SuccessStoryTechnologies>
                <SuccessStoryShare
                    title={companyName}
                    shareStoryText={shareStoryTitle}
                    copiedToClipboardText={copiedToClipboardText}
                ></SuccessStoryShare>
            </div>
            <div className="success-story-body">{contentReact}</div>
            <div className="success-story-testimony">
                <SuccessStoryTestimony
                    content={testimony}
                    author={testimonyAuthor}
                    role={testimonyAuthorRole}
                    quotationMark={quotationMarksIcon.file.url}
                    quotationMarkDark={quotationMarksIconDark.file.url}
                ></SuccessStoryTestimony>
            </div>
            {images.length > 0 && (
                <div className="success-story-images">
                    <CardSlider
                        title={sliderImagesTitle}
                        titleSize={isDesktop ? TextSize.XS : TextSize.S}
                    >
                        {images.map((image, index) => (
                            <div className="embla__slide" key={index}>
                                <img
                                    key={index}
                                    src={image.file.url}
                                    alt="success story image"
                                    className="success-story-image"
                                ></img>
                            </div>
                        ))}
                    </CardSlider>
                </div>
            )}
            <div className="success-story-about">{aboutContentReact}</div>
        </div>
    )
}
