import clsx from 'clsx'
import ImageSwitcher from 'components/ImageSwitcher'
import { TuixPaperComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { BorderRadiusPaper, PaperElevation, TextSize, TextVariant } from 'tuix-webcomponents'
import './SuccessStoryTestimony.scss'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'

export interface Props {
    content: string
    author: string
    role: string
    className?: string
    quotationMark: string
    quotationMarkDark: string
}

export default ({
    content,
    author,
    role,
    quotationMark,
    quotationMarkDark,
}: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isMobile } = useDeviceWidth()

    return (
        <TuixPaperComponent
            elevation={PaperElevation.VARIANT_2}
            dark={isDarkMode}
            borderRadius={BorderRadiusPaper.MEDIUM}
        >
            <div className={clsx('testimony-paper', isDarkMode && 'dark')}>
                <ImageSwitcher
                    lightImageURL={quotationMark}
                    darkImageURL={quotationMarkDark}
                    className="testimony-quotation-mark"
                ></ImageSwitcher>
                <TuixTextComponent
                    color={TextVariant.GREY}
                    dark={isDarkMode}
                    size={isMobile ? TextSize.XS : TextSize.S}
                >
                    <p>{content}</p>
                </TuixTextComponent>
                <TuixTextComponent color={TextVariant.GREY} dark={isDarkMode} size={TextSize.S}>
                    <h5>{author}</h5>
                </TuixTextComponent>
                <TuixTextComponent color={TextVariant.GREY} dark={isDarkMode} size={TextSize.Body1}>
                    <h6>{role}</h6>
                </TuixTextComponent>
            </div>
        </TuixPaperComponent>
    )
}
