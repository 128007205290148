import clsx from 'clsx'
import { TuixButtonComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { Link } from 'gatsby'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import { getLanguageByLocale } from 'utils/language'
import './SuccessStoryCallToAction.scss'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'

export interface Props {
    questionText: string
    actionText: string
    locale: Locale
    contactUsText: string
}

export default ({ actionText, questionText, locale, contactUsText }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isTablet } = useDeviceWidth()

    return (
        <div className="success-story-call-to-action">
            <TuixTextComponent
                size={isTablet ? TextSize.L : TextSize.XL}
                dark={isDarkMode}
                className="call-to-action-text-container"
            >
                <p className="call-to-action-text">{questionText}</p>
            </TuixTextComponent>
            <TuixTextComponent
                size={isTablet ? TextSize.L : TextSize.XL}
                dark={isDarkMode}
                className="call-to-action-text-container"
            >
                <p className="call-to-action-text">{actionText}</p>
            </TuixTextComponent>
            <Link to={`/${getLanguageByLocale(locale)}/contact-us`} className="story-link">
                <TuixButtonComponent dark={isDarkMode}>{contactUsText}</TuixButtonComponent>
            </Link>
        </div>
    )
}
