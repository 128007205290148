import clsx from 'clsx'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import './SucessStoryCompanyInfo.scss'

export interface Props {
    country: string
    industry: string
    stats?: string[]
    aboutElement: React.ReactNode
    countryTitle: string
    industryTitle: string
}

export default ({
    country,
    industry,
    stats,
    aboutElement,
    countryTitle,
    industryTitle,
}: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className={clsx('success-story-company-info-container', isDarkMode && 'dark')}>
            <div className="success-story-company-details">
                <div>
                    <TuixTextComponent
                        color={TextVariant.TITLE_BLUE}
                        dark={isDarkMode}
                        size={TextSize.XS}
                        className="company-info-subtitle"
                    >
                        <h4>{industryTitle}</h4>
                    </TuixTextComponent>
                    <TuixTextComponent
                        dark={isDarkMode}
                        size={TextSize.Body1}
                        className="company-info-value"
                    >
                        <p>{industry}</p>
                    </TuixTextComponent>
                    <TuixTextComponent
                        color={TextVariant.TITLE_BLUE}
                        dark={isDarkMode}
                        size={TextSize.XS}
                        className="company-info-subtitle"
                    >
                        <h4>{countryTitle}</h4>
                    </TuixTextComponent>
                    <TuixTextComponent
                        dark={isDarkMode}
                        size={TextSize.Body1}
                        className="company-info-value"
                    >
                        <p>{country}</p>
                    </TuixTextComponent>
                </div>
                <div>
                    {stats &&
                        stats.map((stat, index) => (
                            <TuixTextComponent
                                color={TextVariant.TITLE_BLUE}
                                dark={isDarkMode}
                                size={TextSize.XS}
                                className="company-info-stats"
                                key={index}
                            >
                                <p>{stat}</p>
                            </TuixTextComponent>
                        ))}
                </div>
            </div>
            <div className="success-story-company-about">{aboutElement}</div>
        </div>
    )
}
